import { Box } from "@rebass/grid/emotion";
import { css } from "@emotion/react";
import React from "react";
import { ReactComponent as Immowelt } from "../../icons/immowelt.svg";

const ImmoweltBadge = () => (
  <Box
    width="80px"
    maxWidth="80px"
    mr={3}
    mb={3}
    css={css`
      border-radius: 12px;
      overflow: hidden;
    `}
  >
    <a
      href="https://www.immowelt.de/profil/0b968dea9cdf44ea97eaa81b55e82cdb"
      target="_blank"
      rel="noreferrer"
    >
      <Immowelt />
    </a>
  </Box>
);

export default ImmoweltBadge;
